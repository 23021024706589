#outlined-multiline-message {
    color: #fff;
    background-color: gray;
    border-radius: 14px;
    padding: 10px;
}
#supportModalButton {
    width: 100%;
    margin: 20px 0 0 0;
    border-radius: 14px;
    color: #000;
}
.contact-subscribe {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
    padding-bottom: 120px;
}

.contact-header {
    color: white;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px;
    margin-bottom: 40px;
}
.contact-content {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.contact-inputs {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: var(--dl-size-size-maxwidth);
}
.contact-textinput {
    width: 100%;
    font-size: 18px;
    line-height: 27px;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0px;
    border-radius: 8px;
    padding: var(--dl-space-space-oneandhalfunits);
    border-bottom-width: 1px;
}
.textarea {
    height: 150px;
}

.contact-text {
    color: #fff;
    font-size: 14px;
}
.contact-button {
    align-self: flex-start;
    background-color: #7bfd98;
    padding: 22px 30px;
}

@media (max-width: 767px) {
    .contact-subscribe {
        padding-top: var(--dl-space-space-oneunit);
        padding-left: var(--dl-space-space-oneandhalfunits);
        padding-right: var(--dl-space-space-oneandhalfunits);
        padding-bottom: var(--dl-space-space-threeunits);
    }

    .contact-header {
        font-size: 24px;
        line-height: 33px;
    }
    .contact-content {
        gap: var(--dl-space-space-oneandhalfunits);
    }
    .contact-inputs {
        gap: var(--dl-space-space-twounits);
    }
    .contact-textinput {
        font-size: 16px;
        line-height: 24px;
        padding: var(--dl-space-space-halfunit);
    }

    .contact-text {
        font-size: 12px;
    }
    .contact-button {
        width: 100%;
        padding: 14px;
    }
}
