.mark-mark {
    gap: var(--dl-space-space-halfunit);
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
}
.mark-icon {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    background-color: #292929;
}
.mark-icon1 {
    fill: #c6ff4b;
    width: 12px;
    height: 12px;
}
.mark-label {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}
