.download-container {
    display: flex;
    justify-content: center;
}

.store-links-containers {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 200px;
}

.store-link {
    display: flex;
    height: 100%;
    margin: 10px;
    flex: 1;
    align-self: center;
}

.google-store-link {
    width: 100%;
    background: url('../../../assets/images/store/google-store-badge.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.apple-store-link {
    background: url('../../../assets/images/store/apple-store-badge.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.store-image {
    /* width: 100%; */
    max-height: 100%;
}
