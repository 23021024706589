.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    overflow-x: hidden;
    align-items: center;
    flex-direction: column;
    background-color: #0f0f0f;
}
.home-section {
    width: 85%;
    height: 970px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: row-reverse;
    justify-content: center;
}
.feature-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.home-image {
    margin: auto;
}
.home-image1 {
    width: 70%;
    margin: auto;
    object-fit: cover;
    margin-right: auto;
    margin-bottom: auto;
}
.home-hero {
    width: 100%;
    display: flex;
    z-index: 2;
    max-width: 1200px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.home-content {
    gap: var(--dl-space-space-fiveunits);
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.scroll-top-container {
    opacity: 0.5;
    display: block;
    background-color: #eaeaea;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 14px;
    padding: 2px;
    z-index: 5;
}
.scroll-top-icon {
    width: 50px;
}
.home-main {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.home-header {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: center;
    flex-direction: column;
}
.home-heading {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
}
.home-caption {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: Poppins;
    line-height: 30px;
}
.home-buttons {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: center;
    flex-direction: row;
}
.home-get-started {
    display: flex;
    background-color: #80ff44;
}
.home-text04 {
    color: #0c100c;
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
}
.home-get-started1 {
    background-color: #2a2a2a;
}
.home-text05 {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
}
.home-image2 {
    display: none;
}
.home-section1 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 120px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
}
.home-text06 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
}
.home-features {
    width: 100%;
    max-width: 1200px;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    user-select: none;
    margin-bottom: 96px;
    flex-direction: row;
    justify-content: center;
}
.carousel-image {
    margin-top: 25px;
    width: 50%;
}

.home-feature {
    display: flex;
    padding-right: 19px;
    justify-content: center;
    align-items: center;
}
.home-text07 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
}
.home-text08 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
}
.home-text09 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
}
.home-text10 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
}
.home-section2 {
    gap: var(--dl-space-space-sixunits);
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: 120px;
    justify-content: center;
}
.home-note {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.home-image4 {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}
.home-image5 {
    width: 100%;
    object-fit: cover;
    padding-top: 10;
}
.home-content1 {
    gap: var(--dl-space-space-threeunits);
    flex: 1;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.home-main1 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.home-heading1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.download-button {
    width: 100%;
    display: flex;
    background-color: #80ff44;
}
.download-button-link-wrapper {
    width: 100%;
}
.home-text15 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
}
.home-footer {
    gap: var(--dl-space-space-fiveunits);
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 120px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
}
.home-content2 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
}
.home-main-content {
    gap: var(--dl-space-space-fiveunits);
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}
.home-content3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
}
.home-main2 {
    gap: var(--dl-space-space-threeunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.home-header1 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.home-branding {
    width: 120px;
    object-fit: cover;
}
.home-text16 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 14px;
    max-width: 260px;
    font-family: Poppins;
    line-height: 21px;
}
.home-socials {
    gap: var(--dl-space-space-unit);
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
}
.home-link {
    display: contents;
}
.home-link1 {
    display: contents;
}
.home-link2 {
    display: contents;
}
.home-categories {
    gap: var(--dl-space-space-fourunits);
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
}
.home-category {
    gap: var(--dl-space-space-unit);
    width: 388px;
    display: flex;
    max-width: 175px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.home-links {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}
.home-copyright {
    gap: var(--dl-space-space-fiveunits);
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.home-text24 {
    color: rgb(196, 196, 196);
    width: 100%;
    font-size: 14px;
    font-family: Poppins;
    line-height: 21px;
}

.home-textinput {
    flex: 1;
    color: #ffffff;
    height: 24px;
    line-height: 24px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    outline-style: none;
    background-color: rgba(217, 217, 217, 0);
}
.home-buy {
    display: flex;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: #80ff44;
}
.home-text25 {
    color: rgb(12, 16, 12);
    display: none;
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
}
.home-text26 {
    color: rgb(12, 16, 12);
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
}

.home-copyright1 {
    gap: var(--dl-space-space-fiveunits);
    flex: 1;
    width: 100%;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.home-text29 {
    color: #c4c4c4;
    width: 100%;
    font-size: 14px;
    font-family: Poppins;
    line-height: 21px;
}
@media (max-width: 991px) {
    .home-section {
        height: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
    .home-image {
        height: 100%;
        display: none;
    }
    .home-hero {
        gap: var(--dl-space-space-fourunits);
        flex: 1;
        position: relative;
        max-width: auto;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .home-content {
        position: relative;
        align-items: center;
    }
    .home-main {
        align-items: center;
    }
    .home-heading {
        color: rgb(255, 255, 255);
        font-size: 64px;
        font-style: normal;
        text-align: left;
        font-family: Poppins;
        font-weight: 600;
    }
    .home-caption {
        color: rgb(255, 255, 255);
        font-size: 20px;
        text-align: left;
        font-family: Poppins;
        line-height: 30px;
    }
    .home-buttons {
        width: 100%;
    }
    .home-get-started {
        flex: 1;
    }
    .home-get-started1 {
        flex: 1;
    }
    .home-image2 {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
    .home-image3 {
        width: 80%;
    }
    .home-text06 {
        text-align: center;
    }
    .home-content2 {
        gap: var(--dl-space-space-threeunits);
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
    .home-copyright {
        display: none;
    }
    .home-copyright1 {
        display: flex;
    }
    .carousel-image {
        width: 50%;
    }
}
@media (max-width: 767px) {
    .home-content {
        gap: var(--dl-space-space-threeunits);
    }
    .home-heading {
        font-size: 40px;
    }
    .home-caption {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-family: Poppins;
        line-height: 24px;
    }
    .home-get-started {
        padding-top: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-unit);
    }
    .home-get-started1 {
        display: none;
    }
    .home-image3 {
        width: 85%;
    }
    .home-section1 {
        padding-top: 60px;
        padding-bottom: 40px;
    }
    .home-text06 {
        font-size: 32px;
        text-align: center;
    }
    .scroll-top-icon {
        width: 30px;
    }
    .home-features {
        gap: var(--dl-space-space-oneandhalfunits);
        margin-top: var(--dl-space-space-threeunits);
        border-color: #5a5a5a;
        margin-bottom: var(--dl-space-space-threeunits);
        flex-direction: column;
    }
    .home-feature {
        width: 100%;
        padding-bottom: var(--dl-space-space-unit);
    }
    .home-text07 {
        font-size: 20px;
    }

    .home-text08 {
        font-size: 20px;
    }

    .home-text09 {
        font-size: 20px;
    }

    .home-text10 {
        font-size: 20px;
    }
    .home-section2 {
        gap: var(--dl-space-space-fourunits);
        padding-bottom: var(--dl-space-space-twounits);
    }
    .home-note {
        flex-direction: column-reverse;
    }
    .home-content3 {
        gap: var(--dl-space-space-threeunits);
        flex-direction: column;
    }
    .home-main2 {
        gap: var(--dl-space-space-twounits);
    }
    .home-categories {
        gap: var(--dl-space-space-twounits);
        flex-direction: column;
    }
    .home-text26 {
        color: rgb(12, 16, 12);
        font-size: 16px;
        font-style: normal;
        font-family: Poppins;
        font-weight: 500;
        line-height: 24px;
    }
    .carousel-image {
        width: 80%;
    }
}
@media (max-width: 479px) {
    .home-main {
        width: 100%;
    }
    .home-header {
        width: 100%;
        align-items: flex-start;
    }
    .home-heading {
        width: 100%;
        max-width: 280px;
        font-family: Poppins;
    }
    .home-caption {
        width: 100%;
    }
    .home-image3 {
        width: 95%;
    }
    .home-section1 {
        padding-bottom: var(--dl-space-space-unit);
    }
    .home-textinput {
        flex: 1;
    }
    .home-text25 {
        color: rgb(12, 16, 12);
        display: flex;
        font-size: 24px;
        font-style: normal;
        font-family: Inter;
        font-weight: 700;
        line-height: 24px;
        white-space: nowrap;
    }
    .home-text26 {
        color: rgb(12, 16, 12);
        display: none;
        font-size: 24px;
        font-style: normal;
        font-family: Inter;
        font-weight: 700;
        line-height: 24px;
    }
    .carousel-image {
        width: 90%;
    }
}
