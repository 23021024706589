body {
    margin: 0;
    background-color: #0f0f0f;
    font-family: 'Poppins', 'Montserrat', sans-serif;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    line-height: 1.15;
}
html {
    scroll-behavior: smooth;
}
html,
body,
#root {
    height: 100%;
}

* {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}
p,
li,
ul,
pre,
div,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
blockquote,
figcaption {
    margin: 0;
    padding: 0;
}
button {
    background-color: transparent;
}
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button,
select {
    text-transform: none;
}

a {
    color: inherit;
    text-decoration: inherit;
}
input {
    padding: 2px 4px;
}
img {
    display: block;
}
