.grecaptcha-badge {
    visibility: hidden;
}
.contentWrapper {
    padding: 40;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
}
.title {
    color: #ffffff;
    font-size: 40;
    font-weight: 900;
    text-align: center;
    margin-bottom: 41px;
    margin-top: 41px;
}
.description {
    text-align: center;
    font-size: 34;
    color: #ffffff;
    margin-bottom: 41px;
}
.redirect {
    display: flex;
    width: 70%;
    height: 60px;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    margin-top: 41px;
    font-size: 26px;
}
.text-link {
    color: #7bfd98;
    text-decoration: underline;
}
:root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #d9d9d9;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-danger-300: #a22020;
    --dl-color-danger-500: #bf2626;
    --dl-color-danger-700: #e14747;
    --dl-color-gray-black: #000000;
    --dl-color-gray-white: #ffffff;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-color-primary-100: #003eb3;
    --dl-color-primary-300: #0074f0;
    --dl-color-primary-500: #14a9ff;
    --dl-color-primary-700: #85dcff;
    --dl-color-success-300: #199033;
    --dl-color-success-500: #32a94c;
    --dl-color-success-700: #4cc366;
    --dl-size-size-xxlarge: 288px;
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-space-space-oneandhalfunits: 24px;
}
.button {
    cursor: pointer;
    display: flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 58px;
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.button:hover {
    opacity: 0.5;
}

.textarea {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
}
.list {
    width: 100%;
    margin: 1em 0px 1em 0px;
    display: block;
    padding: 0px 0px 0px 1.5rem;
    list-style-type: none;
    list-style-position: outside;
}
.list-item {
    display: list-item;
}
.teleport-show {
    display: flex !important;
    transform: none !important;
}
.nav-link {
    color: #fff;
    font-size: 16px;
    font-style: Medium;
    text-align: left;
    transition: 0.3s;
    font-family: Poppins;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
}
.nav-link:hover {
    opacity: 0.5;
}
.get-started {
    cursor: pointer;
    display: flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    border-color: transparent;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 58px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
    text-decoration: none;
    background-color: rgba(42, 42, 42, 1);
}
.get-started:hover {
    opacity: 0.5;
}
.avatar {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}
.feature {
    gap: var(--dl-space-space-halfunit);
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
}

.section-head {
    color: rgb(128, 255, 68);
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.section-heading {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 48px;
}
.section-description {
    color: rgb(204, 204, 204);
    width: 100%;
    max-width: 600px;
    font-family: Poppins;
    line-height: 28px;
}

.side {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(130px);
}
.switch {
    cursor: pointer;
    display: flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 48px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
    background-color: #0f0f0f;
}
.switch:hover {
    opacity: 0.5;
}
.social {
    width: 24px;
    cursor: pointer;
    height: 24px;
    object-fit: cover;
    transition: 0.3s;
    text-decoration: none;
}
.social:hover {
    opacity: 0.5;
}
.footer-header {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 30px;
}
.footer-link {
    color: rgb(204, 204, 204);
    font-size: 14px;
    font-family: Poppins;
    line-height: 21px;
}

@media (max-width: 767px) {
    .nav-link {
        color: #fff;
    }
}
