.container {
    align-items: center;
    background-color: #0f0f0f;
    text-align: center;
    display: flex;
    padding: 20px 0;
    color: white;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.logo {
    width: 100px;
}

/* // header */
.questionHeader {
    padding: 15px;
}
.questionHeaderText {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}

/* // question input area */
.questionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
}
.questionInput {
    width: 100%;
    font: inherit;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    background-color: #0f0f0f;
    height: 59px;

    border-left: 5px solid #7bfd98;
    padding: 5px 0 5px 10px;
    outline: none;
    resize: none;
    border-radius: 2px;
}
.disabledSubmitButton {
    background: linear-gradient(
        170deg,
        rgba(141, 202, 204, 0.4),
        rgba(158, 198, 167, 0.4),
        rgba(204, 198, 143, 0.4)
    );
}
.enabledSubmitButton {
    background: linear-gradient(170deg, #31ccd1, #7bfd98, #ecd82c);
}
.submitQuestionButton {
    color: #000000;
    width: 50%;
    margin: 30px 0;
    padding: 10px;
    border-radius: 14px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    outline: none;
    border: none;
}

.topUsedBoostDisplayHeader {
    margin: 10px 0;
}

.topUsedBoostDisplayEntry {
    margin-left: 8px;
    font-weight: 600;
}
.topUsedBoostOrderNumber {
    margin-left: 10px;
}
.topBidRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 5px;
}
.bidRowsContainer {
    background-color: #1c1e24;
    border: 1px solid #2e2f36;
    border-radius: 14px;
    padding: 5px;
}

.openButton {
    padding: 0 10px;
    font-size: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 75%;
    height: 60px;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    text-decoration: none;
    border-radius: 15px;
    margin-top: 41px;
}

.optionText {
    margin-bottom: 25px;
    margin-top: 30px;
    font-size: 24px;
}

.storeLink {
    margin: 5px;
    transition: transform 0.2s;
}
.storeLink:active {
    transform: scale(1.1);
}

.storeImage {
    width: 200px;
    mix-blend-mode: screen;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.delimiter {
    margin-top: 40px;
    width: 90%;
    height: 1px;
    background-color: rgba(204, 204, 204, 0.2);
    border: none;
}
.storeContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgb(244, 244, 244);
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    border: 2px solid #000;
    border-radius: 14px;
    padding: 15px;
    width: 600px;
    max-width: 95%;
    outline: 0;
}
.modalButtonsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-between;
}
.secondary-text {
    font-size: 12px;
}
#questionModalLeftButton {
    color: #000;
    text-decoration: underline;
    flex: 1;
    padding: 0;
    border-radius: 14px;
}
#questionModalRightButton {
    color: #000;
    font-size: 14px;
    flex: 2;
    margin-left: 10px;
    border-radius: 14px;
}
#roomHomeIcon {
    position: absolute;
    left: 20px;
    top: 20px;
}
.headerContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
