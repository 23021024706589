.navbar-navbar {
    width: 100%;
    display: flex;
    z-index: 1000;
    position: relative;
    max-width: 1200px;
    align-self: auto;
    align-items: center;
    flex-shrink: 1;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
}
.navbar-branding-logo {
    width: var(--dl-size-size-large);
    box-sizing: content-box;
}
.navbar-nav-content {
    gap: var(--dl-space-space-threeunits);
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-direction: row;
    justify-content: flex-start;
}
.navbar-nav-links {
    gap: var(--dl-space-space-threeunits);
    display: flex;
    align-items: flex-start;
    border-color: transparent;
}
.navbar-link {
    text-decoration: none;
    cursor: pointer;
}
.navbar-text {
    color: rgba(255, 255, 255, 1);
    align-self: auto;
    font-style: Medium;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    text-decoration: none;
}
.navbar-hamburger {
    display: none;
}
.navbar-image {
    width: 100px;
    object-fit: cover;
}
.navbar-mobile-menu {
    gap: var(--dl-space-space-twounits);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: var(--dl-space-space-twounits);
    z-index: 100;
    position: fixed;
    transform: translateX(100%);
    transition: 0.3s;
    align-items: stretch;
    flex-direction: column;
    background-color: #000;
}
.navbar-branding {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.navbar-image1 {
    width: 100px;
    object-fit: cover;
}
.navbar-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.navbar-icon {
    width: 24px;
    height: 24px;
}
.navbar-nav-links1 {
    gap: var(--dl-space-space-unit);
    color: var(--dl-color-gray-black);
    display: flex;
    flex-direction: column;
}
.navbar-text1 {
    color: rgba(255, 255, 255, 1);
    align-self: auto;
    font-style: Medium;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    text-decoration: none;
}

@media (max-width: 991px) {
    .navbar-branding-logo {
        width: var(--dl-size-size-large);
    }
    .navbar-text {
        color: rgb(255, 255, 255);
        font-family: Poppins;
        font-weight: 500;
        text-decoration: none;
    }
}
@media (max-width: 767px) {
    .navbar-navbar {
        padding-bottom: var(--dl-space-space-fourunits);
    }
    .navbar-branding-logo {
        width: 80px;
    }
    .navbar-nav-links {
        display: none;
    }
    .navbar-get-started {
        display: none;
    }
    .navbar-hamburger {
        display: flex;
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }
    .navbar-image {
        width: 16px;
        height: 14px;
    }
    .navbar-branding {
        width: 100%;
    }
    .navbar-nav-links1 {
        margin-top: var(--dl-space-space-twounits);
        align-items: flex-start;
        margin-bottom: var(--dl-space-space-twounits);
        justify-content: center;
    }
}
