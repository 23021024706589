.faq-container {
    gap: 120px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.faq-join {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
}
.faq-title {
    color: rgb(255, 255, 255);
    font-size: 70px;

    font-style: normal;
    font-weight: 600;
}
.faq-more {
    gap: var(--dl-space-space-fiveunits);
    width: 100%;
    display: flex;
    z-index: 100;

    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
    padding-bottom: 120px;
}
.faq-header {
    gap: var(--dl-space-space-oneandhalfunits);
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.faq-divide {
    width: 100px;
    height: 1px;
    display: flex;
    margin-top: 4px;
    align-items: center;
    flex-direction: row;
    background-color: #ffffff;
}
.faq-heading {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.faq-title1 {
    font-size: 50px;
    max-width: 900px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px;
}
.faq-accordions {
    gap: var(--dl-space-space-fourunits);
    color: white;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

@media (max-width: 767px) {
    .faq-container {
        gap: var(--dl-space-space-fourunits);
        padding-bottom: var(--dl-space-space-threeunits);
    }

    .faq-join {
        gap: var(--dl-space-space-oneandhalfunits);
        align-items: center;
        padding-left: var(--dl-space-space-oneandhalfunits);
        padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .faq-title {
        font-size: 30px;
        line-height: 33px;
    }
    .faq-more {
        gap: var(--dl-space-space-threeunits);
        padding-top: 0px;
        padding-left: var(--dl-space-space-oneandhalfunits);
        padding-right: var(--dl-space-space-oneandhalfunits);
        padding-bottom: 0px;
    }
    .faq-divide {
        width: 50px;
    }
    .faq-heading {
        gap: var(--dl-space-space-unit);
    }
    .faq-title1 {
        font-size: 24px;
        line-height: 33px;
    }
}
@media (max-width: 479px) {
    .faq-title {
        margin-top: 20px;
    }
    .faq-divide {
        width: 20px;
    }
}
